.main h1 {
    text-align: center;
    width: 70%;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
}

.main p {
    text-align: center;
    margin: 20px auto;
    width: 50%;
    font-weight: 800;
    color: gray;
}

.members {
    width: 50%;
    flex-wrap: wrap;
    display: flex;
    margin: 0 auto;
    justify-content: space-evenly;
}

.page {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    margin: 20px 0;
    background-color: white;
    background-position: center;
}

.page h4 {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 20px;
}

.pmain p {
    text-align: center;
    margin: auto;
    font-weight: 700;
    color: azure;
    width: 90%;
}

.pmain p a {
    text-decoration: none;
}

.page .pmain {
    position: absolute;
    display: none;
    background: rgba(12, 12, 12, 0.58);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    object-fit: cover;
}

.page:hover .pmain {
    padding-top: 30px;
    bottom: 1px;
    display: block;
}

#back {
    display: inline-block;
    margin: 40px auto;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 120%;
    padding: 5px 10px;
    background-color: black;
    color: azure;
    border-radius: 10px;
    transition: 0.4s;
}

#back:hover {
    color: black;
    background-color: azure;
}

img {
    height: auto;
}