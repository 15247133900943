.ChartDiv {
    margin: 35px auto;
    width: 90%;
    height: 100%;
    padding: 50px 10%;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.452);
}

.ChartDiv canvas {
    /* height: 100%; */
    width: 100%;
    /* margin: auto auto; */
    /* color: white !important; */
}