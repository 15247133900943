.WeatherCard {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.WeatherCard[data-visible=false] {
    height: 0%;
    transition-delay: 0.4s;
}

.Date {
    font-family: 'Josefin Sans', sans-serif;
    font-size: larger;
    color: rgb(0, 0, 0);
    margin: 50px 0 0 50px;
    text-shadow: 0 0 2px rgba(5, 5, 5, 0.375);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out;
}

.Date[data-visible=false] {
    opacity: 0;
}