.backgroundImage {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.backgroundImage[data-visible=false] {
    height: 0;
}

.backgroundImage img {
    width: 100%;
}

.backgroundImage li {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: -1;
    transition: 0.5s ease-in-out;
}

.backgroundImage li.activeBg {
    z-index: 1;
    transition: 0.5s ease-in-out;
    opacity: 1 !important;
}