.NavBar {
    /* position: absolute; */
    width: 100%;
    height: 50px;
    background-color: orange;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Alegreya Sans SC', sans-serif;
    font-weight: 900;
}

.LogoNName {
    font-family: 'Fasthand', cursive;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(4px);
    margin: 0 0 0 20px;
}

.NavBar h1 {
    color: aliceblue;
    margin: 0 0 0 25px;
    font-size: 1.3rem;
}

.NavBar h1 span {
    margin: 0 20px 0 0;
}

.navLink {
    all: unset;
    text-decoration: none;
    background-color: transparent;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 10px 0 10px 0;
    transition: 0.2s ease-in-out;
}

.navLink:hover {
    background-color: rgb(176, 106, 0);
}

.active {
    background-color: rgb(176, 106, 0);
}