.Notification {
    top: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    z-index: 2;
    overflow: hidden;
    font-family: 'Titillium Web', sans-serif;
    font-size: xx-small;
}

.entering h1 {
    width: 0;
    margin: 0 0 0 10px;
    overflow: hidden;
    animation: messageAnimation 1s 1s ease-in-out normal forwards;
    animation-delay: 1s;
}

.exiting h1 {
    width: 10rem;
    margin: 0 0 0 10px;
    overflow: hidden;
    animation: messageAnimation 1s ease-in-out reverse forwards;
    animation-direction: reverse;
}

.entering svg {
    animation: iconAnimation 1s ease-in-out normal forwards;
}

.exiting svg {
    animation: iconAnimation 1s 1s ease-in-out reverse forwards;
}

@keyframes messageAnimation {
    0% {
        width: 0;
        opacity: 0;
        transform: translateX(-10px);
    }

    100% {
        opacity: 1;
        width: 6rem;
        transform: translateX(0px);
    }
}

@keyframes iconAnimation {
    0% {
        opacity: 0;
        transform: translateY(-5px) rotate(0deg);
    }

    100% {
        opacity: 1;
        transform: translateY(0px) rotate(360deg);
    }
}