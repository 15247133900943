@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mali:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fasthand&display=swap');

body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu' !important;
}

* {
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#mapContainer {
  width: calc(100vw);
  height: calc(100vh - 50px);
  z-index: 0;
}